<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
    <el-drawer v-model="formDrawerVisible2" size="30%" title="详情">
      <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
        <formSee :columns="addColumns" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { noticeList, noticeSubmit, noticeUpdateStatus } from '@/apis/common'
import listToolbar from '@/components/system/listToolbar.vue'
import formSee from '@/components/system/formSee.vue'
import Form from '@/components/system/form.vue'
import listTable from '@/components/system/listTable.vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'announcement',
  components: { listTable, Form, formSee, listToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      formDrawerVisible: false,
      formDrawerVisible2: false,
      typeForm: 'add',
      toolbar: [
        {type: 'input', label: '标题搜索', model: 'title', value: ''},
        {type: 'select', label: '文件类型', model: 'file_type', value: '', options: [
          {label: '全部', value: ''},
          {label: '图片', value: '1'},
          {label: '文档', value: '2'},
          {label: '音频', value: '3'},
          {label: '视频', value: '4'},
        ]},
        {type: 'select', label: '显示状态', model: 'is_show', value: '', options: [
            {label: '全部', value: ''},
            {label: '不显示', value: '0'},
            {label: '显示', value: '1'},
        ]},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      formData: {
        title: '',
        file_type: '',
        is_show: '',
      },
      list: [],
      loading: false,
      columns: [
        {label: '公告ID', prop: 'id', width: 100, type: 'text'},
        {label: '标题', prop: 'title', width: 300, type: 'text'},
        {label: '状态', prop: 'is_show', width: 100, type: 'switch', change: (e,v) => this.changeStatus(e,v)},
        {label: '文件类型', prop: 'file_type', width: 100, type: 'tag', arr: [{key: 1,type: 'success', label: '图片'}, {key: 2,type: 'success', label: '文档'}, {key: 3,type: 'success', label: '音频'}, {key: 4,type: 'success', label: '视频'}]},
        {label: '发布时间', prop: 'date', width: 150, type: 'text'},
        {label: '操作', width: 180, type: 'but', arr: [
            {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
        ]}
      ],
      addColumns: [
        {label: '公告标题', prop: 'title', type: 'input',value: ''},
        {label: '简介', prop: 'descr', type: 'textarea',value: ''},
        {label: '发布时间', prop: 'date', type: 'date',value: ''},
        {label: '文件类型', prop: 'file_type', type: 'select', value: '',options: [
            {label: '图片', value: 1},
            {label: '文档', value: 2},
            {label: '音频', value: 3},
            {label: '视频', value: 4},
        ]},
        {label: '显示状态', prop: 'is_show', type: 'select', value: '',options: [
            {label: '不显示', value: 0},
            {label: '显示', value: 1}
          ]},
        {label: '文件地址', prop: 'file_url', type: 'file',value: '', limit: 1},
      ],
      addFormData: {},
    }
  },
  mounted() {
    this.getNoticeList()
  },
  methods: {
    submit(e,v) {
      let form = {
        admin_id: this.info.id,
        notice_id: v === 'edit' ? this.addFormData.id : '',
        ...e
      }
      noticeSubmit(form).then(res => {
        this.formDrawerVisible = false
        ElMessage({
          showClose: true,
          message: '添加成功',
          type: 'success',
        })
        this.getNoticeList()
      })
    },
    // 查看
    seeDetails(row) {
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
        if(item.prop === 'is_show') {
          item.value = row.is_show? 1 : 0
        }
      })
      this.formDrawerVisible2 = true
    },
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
        if(item.prop === 'is_show') {
          item.value = row.is_show? 1 : 0
        }
      })
      this.formDrawerVisible = true
    },
    changeStatus(e,v) {
      noticeUpdateStatus({
        notice_id: v.id,
        is_show: e ? 1 : 0,
        admin_id: this.info.id,
      }).then(res => {
      })
    },
    pageChange(e) {
      this.page = e
      this.getNoticeList()
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getNoticeList()
    },
    getNoticeList() {
      this.loading = true
      noticeList({
        ...this.formData,
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list.map(x => {
          x.is_show = x.is_show === 1
          return x
        })
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
